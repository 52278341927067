import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import AdminLayout from '../layouts/AdminLayout';
import DataTable from 'react-data-table-component';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select'; // React Select for multi-select dropdown

const DailyRemainder = () => {
    const [remainderList, setRemainderList] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentReminderId, setCurrentReminderId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [notificationType, setNotificationType] = useState('Only Downloaded Users');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const remindersCollection = collection(db, 'reminders');
                const q = query(remindersCollection, orderBy('time', 'desc'));
                const remindersSnapshot = await getDocs(q);
                const remindersList = remindersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setRemainderList(remindersList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                })).filter((user) => user.FCMtoken);
                setUsers(usersList);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();

        fetchData();
    }, []);

    const userOptions = users.map(user => ({
        value: user.id,
        label: user.email
    }));
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        message: Yup.string().required('Message is required'),
        time: Yup.string().required('Time is required'),
        date: Yup.mixed().test(
            'time-required',
            'Date is required for "Date Wise" notifications',
            function (value) {
                if (this.parent.notificationType === 'Date Wise') {
                    return !!value;
                }
                return true;
            }
        ),
        idleDays: Yup.mixed().test(
            'idleDays-required',
            'Idle days are required for "Idle Days Users"',
            function (value) {
                if (this.parent.notificationType === 'Idle Days Users') {
                    return !!value;
                }
                return true;
            }
        ),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            date: '',
            time: '',
            notificationType: 'Only Downloaded Users',
            idleDays: '',
            selectedUsers: [],

        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setSaving(true);
                const reminderData = {
                    title: values.title,
                    message: values.message,
                    date: values.notificationType === 'Date Wise' ? values.date : null,
                    notificationType: values.notificationType,
                    time: values.time,
                    idleDays: values.notificationType === 'Idle Days Users' ? values.idleDays : null,
                    selectedUsers: values.selectedUsers,

                };

                if (editing) {
                    const reminderRef = doc(db, 'reminders', currentReminderId);
                    await updateDoc(reminderRef, reminderData);
                    setRemainderList((prev) =>
                        prev.map((item) => (item.id === currentReminderId ? { ...item, ...reminderData } : item))
                    );
                    setSuccessMessage('Reminder updated successfully!');
                } else {
                    const docRef = await addDoc(collection(db, 'reminders'), reminderData);
                    reminderData.id = docRef.id;
                    setRemainderList((prev) => [reminderData, ...prev]);
                    setSuccessMessage('Reminder added successfully!');
                }

                setTimeout(() => setSuccessMessage(''), 3000);
                setSaving(false);
                resetForm();
                setEditing(false);
                setCurrentReminderId(null);
                setShowModal(false);
            } catch (error) {
                console.error("Error saving reminder:", error);
                setSaving(false);
            }
        },
    });

    const handleEdit = (reminder) => {
        setEditing(true);
        setCurrentReminderId(reminder.id);
        formik.setValues({
            title: reminder.title,
            message: reminder.message,
            time: reminder.time,
            date: reminder.date,
            notificationType: reminder.notificationType,
            idleDays: reminder.idleDays || '',
            selectedUsers: reminder.selectedUsers || [],

        });
        setShowModal(true);
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'reminders', deleteId));
            setRemainderList((prev) => prev.filter((reminder) => reminder.id !== deleteId));
            setSuccessMessage('Reminder deleted successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);
            setShowDeleteModal(false);
            setDeleteId(null);
        } catch (error) {
            console.error("Error deleting reminder:", error);
        }
    };

    const columns = [
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Message',
            selector: (row) => row.message,
        },
        {
            name: 'Time',
            selector: (row) => row.time,
        },

        {
            name: 'Users',
            cell: (row) => (
                <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {row.selectedUsers?.map(userId => users.find(user => user.id === userId)?.email).join(', ') || 'All Users'}
                </div>
            ),
            wrap: true, // Ensures text wraps inside the cell
        },

        {
            name: 'Notification Details',
            selector: row => {
                if (row.notificationType === 'Idle Days Users') {
                    return `${row.notificationType} (Idle Days: ${row.idleDays || 'N/A'})`;
                }
                if (row.notificationType === 'Date Wise') {
                    return `${row.notificationType} (Date: ${new Date(row.date).toLocaleDateString() || 'N/A'})`;
                }
                return row.notificationType;
            },
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button variant="warning" size="sm" className="me-2" onClick={() => handleEdit(row)}>
                        Edit
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => handleDeleteConfirmation(row.id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Metamindful
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Notification Schedule</li>
                        </ul>
                    </div>
                    <Button
                        className="btn btn-sm fw-bold btn-primary"
                        onClick={() => {
                            formik.resetForm();
                            setEditing(false);
                            setShowModal(true);
                        }}
                    >
                        Add New Schedule
                    </Button>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="container-fluid">
                    <div className="card card-body">
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}

                        {/* Add/Edit Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{editing ? 'Edit Reminder' : 'New Reminder'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Notification Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="notificationType"
                                            value={formik.values.notificationType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setNotificationType(e.target.value);
                                            }}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="Only Downloaded Users">Only Downloaded Users</option>
                                            <option value="No Chat Users">No Chat Users</option>
                                            <option value="Idle Days Users">Idle Days Users</option>
                                            <option value="Date Wise">Date Wise</option>
                                            <option value="New Day">New Day</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.title && !!formik.errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="message"
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.message && !!formik.errors.message}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {notificationType === 'Idle Days Users' && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Idle Days</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="idleDays"
                                                value={formik.values.idleDays}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.idleDays && !!formik.errors.idleDays}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.idleDays}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                    <Form.Group className="mb-3">
                                        <Form.Label>Time</Form.Label>
                                        <Form.Control
                                            type="time"
                                            name="time"
                                            value={formik.values.time}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.time && !!formik.errors.time}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.time}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {notificationType === 'Date Wise' && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.date && !!formik.errors.date}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.date}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                    <Form.Group>
                                        <Form.Label>Select Users</Form.Label>
                                        <Select
                                            options={userOptions}
                                            isMulti
                                            isSearchable
                                            onChange={(selectedOptions) => {
                                                formik.setFieldValue('selectedUsers', selectedOptions.map(option => option.value));
                                            }}
                                            value={userOptions.filter(option => formik.values.selectedUsers.includes(option.value))}
                                            placeholder="Search & Select Users"
                                            name="selectedUsers"
                                            classNamePrefix="react-select"
                                        />
                                        {false &&
                                        <Form.Control as="select" multiple name="selectedUsers" value={formik.values.selectedUsers} onChange={formik.handleChange}>
                                            {users.map(user => (
                                                <option key={user.id} value={user.id}>{user.email}</option>
                                            ))}
                                        </Form.Control>
                                        }
                                    </Form.Group>
                                    <div className="text-end">
                                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" type="submit" disabled={saving}>
                                            {saving ? 'Saving...' : editing ? 'Update Reminder' : 'Save Reminder'}
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        {/* Delete Confirmation Modal */}
                        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete this reminder?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Data Table */}
                        <DataTable
                            columns={columns}
                            data={remainderList}
                            pagination
                            progressPending={loading}
                            progressComponent={
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            noDataComponent={<div>No reminders found.</div>}
                        />
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default DailyRemainder;
