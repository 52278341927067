import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Home from './pages/Home';
import Users from './pages/Users';
import SendNotifications from "./pages/SendNotifications";
import DailyRemainder from "./pages/DailyRemainder";
import Topics from "./pages/Topics";
import BlogIndex from "./pages/blogs/Index";
import BlogView from "./pages/blogs/View";
import BlogCreate from "./pages/blogs/Create";
import CommentsIndex from "./pages/blogs/comments/Index";
import Notifications from "./pages/Notifications";
import PrivateRoute from './components/PrivateRoute';

const App = () => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    return (
        <Router>
            <Routes>
                {/* Redirect to home if user is already logged in */}
                <Route path="/" element={isAuthenticated ? <Home /> : <Login />} />

                {/* Group protected routes under PrivateRoute */}
                {['/home', '/users', '/send-notifications', '/daily-remainder','/topics','/notifications','/blogs','/blogs/create','/blogs/edit/:id','/blogs/:id','/blogs/:id/comments'].map((path) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <PrivateRoute>
                                {path === '/home' && <Home />}
                                {path === '/users' && <Users />}
                                {path === '/send-notifications' && <SendNotifications />}
                                {path === '/daily-remainder' && <DailyRemainder />}
                                {path === '/topics' && <Topics />}
                                {path === '/blogs' && <BlogIndex />}
                                {path === '/blogs/create' && <BlogCreate />}
                                {path === '/blogs/edit/:id' && <BlogCreate />}
                                {path === '/blogs/:id' && <BlogView />}
                                {path === '/blogs/:id/comments' && <CommentsIndex />}

                                {path === '/notifications' && <Notifications />}
                            </PrivateRoute>
                        }
                    />
                ))}
            </Routes>
        </Router>
    );
};

export default App;
