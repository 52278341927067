import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import AdminLayout from "../../layouts/AdminLayout";
import { Eye, Pencil, Trash } from "react-bootstrap-icons";

const BlogIndex = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const API_URL = "https://notification.metamindful.ai/api/blogs"; // Replace with your actual API URL

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setLoading(true);
                const response = await axios.get(API_URL);
                setBlogs(response.data);
            } catch (error) {
                console.error("Error fetching blogs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this blog?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`${API_URL}/${id}`);
            setBlogs(blogs.filter((blog) => blog._id !== id));
        } catch (error) {
            console.error("Error deleting blog:", error);
        }
    };

    const columns = [
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: true,
            cell: (row) => (
                <div title={row.title}>
                    {row.title.length > 100 ? `${row.title.slice(0, 100)}...` : row.title}
                </div>
            ),
        },
        {
            name: "Short Description",
            selector: (row) => row.shortDescription,
            sortable: true,
            cell: (row) => (
                <div title={row.shortDescription}>
                    {row.shortDescription.length > 200
                        ? `${row.shortDescription.slice(0, 200)}...`
                        : row.shortDescription}
                </div>
            ),
        },
        {
            name: "Published",
            selector: (row) => (row.isPublished ? "Yes" : "No"),
            sortable: true,
        },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toLocaleDateString(),
            sortable: true,
        },
        {
            name: "Comments",
            cell: (row) => (
                <Link
                    to={`/blogs/${row._id}/comments`}
                    className="btn btn-secondary btn-sm"
                    title="View Comments"
                >
                    Comments
                </Link>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <>
                    <Link to={`/blogs/${row._id}`} className="btn btn-primary btn-sm me-2" title="View">
                        <Eye size={18} />
                    </Link>
                    <Link to={`/blogs/edit/${row._id}`} className="btn btn-primary btn-sm me-2" title="Edit">
                        <Pencil size={18} />
                    </Link>
                    <Link
                        to="#"
                        className="btn btn-primary btn-sm me-2"
                        title="Delete"
                        onClick={(e) => {
                            e.preventDefault();
                            handleDelete(row._id);
                        }}
                    >
                        <Trash size={18} />
                    </Link>
                </>
            ),
        },
    ];

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Metamindful
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Blogs</li>
                        </ul>
                    </div>

                    <Link to="/blogs/create" className="btn btn-sm fw-bold btn-primary">
                        Add Blog
                    </Link>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <DataTable
                            columns={columns}
                            data={blogs}
                            progressPending={loading}
                            progressComponent={<div>Loading...</div>}
                            pagination
                            highlightOnHover
                            striped
                            sortIcon={<span className="caret" />}
                            noDataComponent={<div>No records found</div>}
                        />
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default BlogIndex;
