import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from '../../layouts/AdminLayout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogCreate = () => {
    const [blog, setBlog] = useState({
        title: "",
        shortDescription: "",
        longDescription: "",
        categories: [],
        image: null,
        isPublished: false,
    });
    const [categoriesInput, setCategoriesInput] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [existingImage, setExistingImage] = useState("");
    const [isLoading, setIsLoading] = useState(false); // State to track loading status
    const navigate = useNavigate();
    const { id } = useParams(); // Get blog ID from URL params (for updating)
    const API_URL = "https://notification.metamindful.ai/api/blogs"; // Replace with your actual API URL

 //   const API_URL = "http://localhost:3001/api/blogs"; // Replace with your actual API URL
    const IMAGE_URL = "https://notification.metamindful.ai/api/"; // Replace with your actual API URL

    // Fetch the blog data if we are updating
    useEffect(() => {
        if (id) {
            axios
                .get(`${API_URL}/${id}`)
                .then((response) => {
                    const blogData = response.data;
                    setBlog({
                        title: blogData.title,
                        shortDescription: blogData.shortDescription,
                        longDescription: blogData.longDescription,
                        categories: blogData.categories || [],
                        image: blogData.image,
                        isPublished: blogData.isPublished || false, // Set isPublished

                    });
                    setEditorValue(blogData.longDescription);
                    setExistingImage(blogData.image); // Set the existing image URL for display
                })
                .catch((error) => {
                    console.error("Error fetching blog:", error);
                    setErrorMessage("Failed to load blog data.");
                });
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBlog({ ...blog, [name]: value });
    };
    const handleCheckboxChange = (e) => {
        setBlog({ ...blog, isPublished: e.target.checked });
    };

    const handleFileChange = (e) => {
        setBlog({ ...blog, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!editorValue) {
            alert("Long description is required.");
            return;
        }

        const formData = new FormData();
        formData.append("title", blog.title);
        formData.append("shortDescription", blog.shortDescription);
        formData.append("longDescription", editorValue);
        formData.append("isPublished", blog.isPublished); // Append isPublished

        if (blog.image) {
            formData.append("image", blog.image);
        }

        setIsLoading(true); // Start loading

        try {
            setSuccessMessage("");
            setErrorMessage("");

            if (id) {
                // Update existing blog if id is present
                await axios.put(`${API_URL}/${id}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                setSuccessMessage("Blog updated successfully!");
            } else {
                // Create a new blog if id is not present
                await axios.post(API_URL, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                setSuccessMessage("Blog created successfully!");
            }

            setTimeout(() => {
                setSuccessMessage("");
                navigate("/blogs");
            }, 700);
        } catch (error) {
            console.error("Error submitting blog:", error);
            setErrorMessage("An error occurred. Please try again.");
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Metamindful
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">
                                <Link to="/blogs" className="text-muted text-hover-primary">
                                    Blogs
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">{id ? "Update Blog" : "Create Blog"}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <h4>{id ? "Update Blog" : "Add New Blog"}</h4>

                        {/* Display success message */}
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}

                        {/* Display error message */}
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={blog.title}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="shortDescription"
                                    value={blog.shortDescription}
                                    onChange={handleInputChange}
                                    rows={2}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Long Description</Form.Label>
                                <ReactQuill
                                    value={editorValue}
                                    onChange={setEditorValue}
                                    theme="snow"

                                    formats={['header', 'font', 'list', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background', 'blockquote', 'code-block']}
                                    placeholder="Start typing here..."  // Add placeholder text

                                    style={{ overflow: 'auto' }} // Adjust height
                                    required
                                />
                            </Form.Group>



                            <Form.Group>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*" // Only accept images
                                />
                            </Form.Group>

                            {existingImage && (
                                <div style={{marginTop:'10px'}}>
                                    <Form.Label>Existing Image</Form.Label>
                                    <img src={`${IMAGE_URL}${existingImage}`} alt="Existing Blog Image" style={{ width: '200px', height: 'auto' }} />
                                </div>
                            )}
                            <div style={{marginTop:'10px'}}>
                                <Form.Group controlId="isPublished">

                                    <Form.Check
                                        type="checkbox"
                                        name="isPublished"
                                        label="Is Published?"
                                        checked={blog.isPublished}
                                        onChange={handleCheckboxChange}
                                        id="isPublishedCheckbox"
                                    />

                                </Form.Group>

                            </div>
                            {isLoading && (
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            )}

                            <div className="text-right" style={{ textAlign: 'right', marginTop: '15px' }}>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isLoading} // Disable button when loading
                                >
                                    {id ? "Update Blog" : "Create Blog"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default BlogCreate;
