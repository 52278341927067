import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../layouts/AdminLayout';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Topics = () => {
    const [topics, setTopics] = useState([]);
    const [filteredTopics, setFilteredTopics] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentTopic, setCurrentTopic] = useState({ name: '', summary: '', description: '',order:1, image: null });
    const [deleteId, setDeleteId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

  //  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/topics`;
    const API_URL = `https://notification.metamindful.ai/api/topics`;

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await axios.get(API_URL);
                setTopics(response.data);
                setFilteredTopics(response.data);
            } catch (error) {
                console.error('Error fetching topics data:', error);
            }
        };

        fetchTopics();
    }, [API_URL]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentTopic({ ...currentTopic, [name]: value });
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setCurrentTopic({ ...currentTopic, image: e.target.files[0] });
    };

    // Handle add/edit form submission
    const handleFormSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('name', currentTopic.name);
            formData.append('summary', currentTopic.summary);
            formData.append('description', currentTopic.description);
            formData.append('order', currentTopic.order);
            if (currentTopic.image) {
                formData.append('image', currentTopic.image);
            }

            if (isEditing) {
                await axios.put(`${API_URL}/${currentTopic._id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } else {
                await axios.post(API_URL, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            }
            setShowModal(false);
            setCurrentTopic({ name: '', summary: '', description: '',order:1, image: null });
            setIsEditing(false);
            const response = await axios.get(API_URL);
            setTopics(response.data);
            setFilteredTopics(response.data);
        } catch (error) {
            console.error('Error saving topic:', error);
        }
    };

    // Handle delete
    const handleDelete = async () => {
        try {
            await axios.delete(`${API_URL}/${deleteId}`);
            setShowDeleteModal(false);
            setDeleteId(null);
            const response = await axios.get(API_URL);
            setTopics(response.data);
            setFilteredTopics(response.data);
        } catch (error) {
            console.error('Error deleting topic:', error);
        }
    };

    const columns = [
        { name: 'Order', selector: row => row.order, sortable: true },
        { name: 'Name', selector: row => row.name, sortable: true },
        { name: 'Summary', selector: row => row.summary, sortable: true },
        { name: 'Description', selector: row => row.description, sortable: true },
        {
            name: 'Image',
            cell: (row) =>
                row.image ? (
                    <img src={`${process.env.REACT_APP_API_BASE_URL}/${row.image}`} alt="Topic" width="50" height="50" />
                ) : (
                    'No Image'
                ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button
                        variant="warning"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                            setIsEditing(true);
                            setCurrentTopic(row);
                            setShowModal(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            setDeleteId(row._id);
                            setShowDeleteModal(true);
                        }}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Metamindful
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Topics</li>
                        </ul>
                    </div>
                    <Button
                        className="btn btn-sm fw-bold btn-primary"
                        onClick={() => {
                            setIsEditing(false);
                            setCurrentTopic({ name: '', summary: '', description: '',order:1, image: null });
                            setShowModal(true);
                        }}
                    >
                        Add Topic
                    </Button>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <DataTable columns={columns} data={filteredTopics} pagination highlightOnHover striped />
                    </div>
                </div>
            </div>

            {/* Add/Edit Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Topic' : 'Add Topic'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Order</Form.Label>
                            <Form.Control
                                type="number"
                                name="order"
                                value={currentTopic.order}
                                onChange={handleInputChange}
                                placeholder="Enter topic order"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={currentTopic.name}
                                onChange={handleInputChange}
                                placeholder="Enter topic name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control
                                type="text"
                                name="summary"
                                value={currentTopic.summary}
                                onChange={handleInputChange}
                                placeholder="Enter topic summary"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={currentTopic.description}
                                onChange={handleInputChange}
                                placeholder="Enter topic description"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        {isEditing ? 'Update' : 'Add'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this topic?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </AdminLayout>
    );
};

export default Topics;
