import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import AdminLayout from '../layouts/AdminLayout';
import DataTable from 'react-data-table-component';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                setLoading(true);
                const notificationsCollection = collection(db, 'notifications');
                const q = query(notificationsCollection, orderBy('sentAt', 'desc')); // Order by latest sent notifications
                const snapshot = await getDocs(q);
                const notificationsList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setNotifications(notificationsList);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching notifications:', err);
                setError('Failed to load notifications.');
                setLoading(false);
            }
        };
        fetchNotifications();
    }, []);

    const columns = [
        {
            name: 'User ID',
            selector: (row) => row.userId,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Notification Type',
            selector: (row) => row.notificationType,
            sortable: true,
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Message',
            selector: (row) => row.message,
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
                <span
                    className={`badge ${row.status === 'success' ? 'bg-success' : 'bg-danger'}`}
                >
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Error',
            selector: (row) => row.error || 'None',
            wrap: true,
        },
        {
            name: 'Sent Date',
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: 'Server Time',
            selector: (row) => row.serverTime?.toDate().toLocaleString() || 'N/A',
            sortable: true,
        },
        {
            name: 'User UTC Offset',
            selector: (row) => row.userUTCOffset || 'N/A',
        },
    ];

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Notification History
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">
                                    Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Notifications</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="container-fluid">
                    <div className="card card-body">
                        {error && <Alert variant="danger">{error}</Alert>}

                        {/* Data Table */}
                        <DataTable
                            columns={columns}
                            data={notifications}
                            pagination
                            progressPending={loading}
                            progressComponent={
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            noDataComponent={<div>No notifications found.</div>}
                        />
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Notifications;
