import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AdminLayout from '../layouts/AdminLayout';
import { db } from '../firebase'; // Adjust the path as needed
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Users = () => {
    const [users, setUsers] = useState([]); // State to store users data
    const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
    const [isActiveFilter, setIsActiveFilter] = useState(''); // Filter state for isActive
    const [genderFilter, setGenderFilter] = useState(''); // Filter state for gender
    const [dayFilter, setDayFilter] = useState(''); // Filter state for day

    useEffect(() => {
        // Fetch users data from Firestore
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => ({
                    id: doc.id, // Add the document ID
                    ...doc.data() // Spread the document data
                }));
                setUsers(usersList); // Set users data
                setFilteredUsers(usersList); // Initialize filtered users
            } catch (error) {
                console.error("Error fetching users data:", error);
            }
        };

        fetchUsers();
    }, []);

    // Apply filters whenever any filter state changes
    useEffect(() => {
        let filtered = [...users];



        if (isActiveFilter !== '') {
        if (isActiveFilter==='true') {

            filtered = filtered.filter(user => user.isActive === true || user.isActive === null || user.isActive === undefined);
        } else{

            filtered = filtered.filter(user => user.isActive === false);
        }

        }

        // Apply gender filter
        if (genderFilter !== '') {
            filtered = filtered.filter(user => user.gender === genderFilter);
        }

        // Apply day filter
        if (dayFilter !== '') {
            filtered = filtered.filter(user => user.day?.toString() === dayFilter);
        }

        setFilteredUsers(filtered);
    }, [isActiveFilter, genderFilter, dayFilter, users]);

    // Define columns for the data table
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true
        },
        {
            name: 'Day',
            selector: row => row.day,
            sortable: true
        },
        {
            name: 'Day Status',
            selector: row => row.dayStatus,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Subscription Type',
            selector: row => row.subscriptionType,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Is Active',
            selector: row => row.isActive === true ? 'Yes' : (row.isActive === null || row.isActive === undefined ? 'Yes' : 'No'),
            sortable: true
        },
        {
            name: 'Last Login Date',
            selector: row => row.lastLoginDate ? new Date(row.lastLoginDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',
        },
        {
            name: 'Last Day Completed Date',
            selector: row => row.lastDayCompletedDate ? new Date(row.lastDayCompletedDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',
        },
        {
            name: 'Subscription End Date',
            selector: row => row.subscriptionEndDate ? new Date(row.subscriptionEndDate.seconds * 1000).toLocaleString() : 'N/A',
            sortable: true,
            width: '250px',
        }
    ];

    // Custom styling for the data table
    const customStyles = {
        headRow: {
            style: {
                borderTop: '1px solid #e3e3e3',
                borderBottom: '2px solid #e3e3e3',
            },
        },
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
                backgroundColor: '#f5f5f5',
                borderRight: '1px solid #e3e3e3',
                borderLeft: '1px solid #e3e3e3',
                borderBottom: '1px solid #e3e3e3',
                padding: '8px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'unset',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                borderRight: '1px solid #e3e3e3',
                borderLeft: '1px solid #e3e3e3',
                borderBottom: '1px solid #e3e3e3',
                padding: '8px',
            },
        },
        rows: {
            style: {
                minHeight: '40px',
                borderBottom: '1px solid #e3e3e3',
            }
        },
        pagination: {
            style: {
                borderTop: '1px solid #e3e3e3',
            }
        }
    };

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Users List</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <div className="mb-3">
                            {/* Filter Dropdowns */}
                            <div className="row">
                                <div className="col-md-4">
                                    <select className="form-select" value={isActiveFilter} onChange={(e) => setIsActiveFilter(e.target.value)}>
                                        <option value="">All Users</option>
                                        <option value="true">Active</option>
                                        <option value="false">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <select className="form-select" value={genderFilter} onChange={(e) => setGenderFilter(e.target.value)}>
                                        <option value="">All Genders</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <select className="form-select" value={dayFilter} onChange={(e) => setDayFilter(e.target.value)}>
                                        <option value="">All Days</option>
                                        <option value="1">Day 1</option>
                                        <option value="2">Day 2</option>
                                        <option value="3">Day 3</option>
                                        {/* Add more days as needed */}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <DataTable
                            columns={columns}
                            data={filteredUsers}
                            pagination
                            highlightOnHover
                            striped
                            responsive
                            customStyles={customStyles}
                            subHeader
                            subHeaderComponent={
                                <input
                                    type="text"
                                    placeholder="Search Users"
                                    className="w-25 form-control"
                                    onChange={(e) => {
                                        const searchText = e.target.value.toLowerCase();
                                        setFilteredUsers(users.filter(user =>
                                            user.name?.toLowerCase().includes(searchText) ||
                                            user.email?.toLowerCase().includes(searchText) ||
                                            user.gender?.toLowerCase().includes(searchText) ||
                                            user.day?.toString().includes(searchText) ||
                                            user.dayStatus?.toLowerCase().includes(searchText) ||
                                            user.subscriptionType?.toLowerCase().includes(searchText)
                                        ));
                                    }}
                                />
                            }
                            style={{ width: '100%' }} // Set the DataTable width to 100%
                        />
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Users;
