import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Select from 'react-select';
import AdminLayout from '../layouts/AdminLayout';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SendNotifications = () => {
    const [users, setUsers] = useState([]);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, 'users');
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        email: doc.data().email,
                        name: doc.data().name,
                        token: doc.data().FCMtoken
                    }))
                    .filter(user => user.token);
                setUsers(usersList);
            } catch (error) {
                console.error("Error fetching users data:", error);
            }
        };

        fetchUsers();
    }, []);

    // Add "Select All" at the beginning
    const options = [
        { value: 'select-all', label: 'Select All' },
        ...users.map(user => ({
            value: user.token,
            label: `${user.name} (${user.email})`
        }))
    ];

    // Custom component to add checkboxes to options
    const customOption = (props) => {
        const { data, isSelected, innerRef, innerProps } = props;

        return (
            <div ref={innerRef} {...innerProps} className="d-flex align-items-center p-2" style={{ cursor: 'pointer' }}>
                <input
                    type="checkbox"
                    checked={isSelected}
                    readOnly
                    style={{ marginRight: 10 }}
                />
                <label>{data.label}</label>
            </div>
        );
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            selectedUsers: [],
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Notification title is required'),
            message: Yup.string().required('Notification message is required'),
            selectedUsers: Yup.array().min(1, 'Please select at least one user').required('Please select users'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setResults([]);

            for (const user of values.selectedUsers) {
                if (user.value === 'select-all') continue; // Skip "Select All" from processing

                const payload = {
                    fcmToken: user.value,
                    title: values.title,
                    body: values.message
                };

                try {
                    const response = await fetch('https://notification.metamindful.ai/api/notification/send', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    const data = await response.json();
                    if (response.ok) {
                        setResults(prev => [...prev, { email: user.label, status: 'Success', response: data }]);
                    } else {
                        setResults(prev => [...prev, { email: user.label, status: 'Failed', response: data }]);
                    }
                } catch (error) {
                    setResults(prev => [...prev, { email: user.label, status: 'Error', response: error.message }]);
                }
            }

            setLoading(false);
        },
    });

    // Handle Select All functionality
    const handleSelectChange = (selectedOptions) => {
        if (!selectedOptions) {
            formik.setFieldValue('selectedUsers', []);
            setAllSelected(false);
            return;
        }

        const isSelectAllSelected = selectedOptions.some(option => option.value === 'select-all');

        if (isSelectAllSelected) {
            if (!allSelected) {
                setAllSelected(true);
                formik.setFieldValue('selectedUsers', options.slice(1)); // Exclude "Select All"
            } else {
                setAllSelected(false);
                formik.setFieldValue('selectedUsers', []);
            }
        } else {
            setAllSelected(false);
            formik.setFieldValue('selectedUsers', selectedOptions);
        }
    };

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Metamindful</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/home" className="text-muted text-hover-primary">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Send Notifications</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="card card-body">
                        <div className="container mt-4">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        className={`form-control mb-2 ${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                        placeholder="Notification Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="title"
                                    />
                                    {formik.touched.title && formik.errors.title && (
                                        <div className="invalid-feedback">{formik.errors.title}</div>
                                    )}

                                    <textarea
                                        className={`form-control ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`}
                                        placeholder="Notification Message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="message"
                                    />
                                    {formik.touched.message && formik.errors.message && (
                                        <div className="invalid-feedback">{formik.errors.message}</div>
                                    )}
                                </div>

                                <div className="mb-4">
                                    <Select
                                        options={options}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{ Option: customOption }}
                                        onChange={handleSelectChange}
                                        value={formik.values.selectedUsers}
                                        placeholder="Select Users to Notify"
                                        name="selectedUsers"
                                    />
                                    {formik.touched.selectedUsers && formik.errors.selectedUsers && (
                                        <div className="text-danger">{formik.errors.selectedUsers}</div>
                                    )}
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-3"
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Notification'}
                                    </button>
                                </div>
                            </form>

                            <div className="mt-4">
                                <h4>Notification Results</h4>
                                <ul>
                                    {results.map((result, index) => (
                                        <li key={index}>
                                            {result.email} - {result.status}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default SendNotifications;
