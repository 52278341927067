import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const BlogView = () => {
    const [blog, setBlog] = useState({});
    const { id } = useParams(); // Get the blog ID from the URL
    const API_URL = `https://notification.metamindful.ai/api/blogs/${id}`;

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(API_URL);
                setBlog(response.data);
            } catch (error) {
                console.error("Error fetching blog:", error);
            }
        };
        fetchBlog();
    }, [id]);

    return (
        <div>
            <h1>{blog.title}</h1>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/${blog.image}`} alt={blog.title} width="500" />
            <p><strong>Categories:</strong> {blog.categories?.join(", ")}</p>
            <p><strong>Description:</strong> {blog.shortDescription}</p>
            <div>
                <strong>Long Description:</strong>
                <p>{blog.longDescription}</p>
            </div>
        </div>
    );
};

export default BlogView;
