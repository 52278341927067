import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import AdminLayout from "../../../layouts/AdminLayout";
import { CheckCircle, XCircle, Trash } from "react-bootstrap-icons";

const CommentsIndex = () => {
    // Get the blog id from the route parameters
    const { id } = useParams();
    const [comments, setComments] = useState([]);
    const [blogTitle, setBlogTitle] = useState("");
    const [loading, setLoading] = useState(true);
   // const COMMENTS_API_URL = "http://localhost:3001/api/comments"; // Replace with your actual API URL
    const COMMENTS_API_URL = "https://notification.metamindful.ai/api/comments"; // Replace with your actual API URL
    const BLOG_API_URL = "https://notification.metamindful.ai/api/blogs"; // Replace with your actual API URL

    // Fetch blog details to display the title
    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${BLOG_API_URL}/${id}`);
                setBlogTitle(response.data.title);
            } catch (error) {
                console.error("Error fetching blog details:", error);
            }
        };

        if (id) {
            fetchBlog();
        }
    }, [id]);

    // Fetch comments for the specific blog
    useEffect(() => {
        const fetchComments = async () => {
            try {
                setLoading(true);
                // Pass the blog id as a query parameter
                const response = await axios.get(`${COMMENTS_API_URL}?blog=${id}`);
                setComments(response.data);
            } catch (error) {
                console.error("Error fetching comments:", error);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchComments();
        }
    }, [id]);

    // Toggle publish status of a comment
    const handleTogglePublish = async (comment) => {
        try {
            const updatedStatus = !comment.isPublished;
            const response = await axios.put(`${COMMENTS_API_URL}/${comment._id}`, {
                isPublished: updatedStatus,
            });
            setComments(
                comments.map((cmt) =>
                    cmt._id === comment._id ? response.data : cmt
                )
            );
        } catch (error) {
            console.error("Error updating comment status:", error);
        }
    };

    // Delete a comment
    const handleDelete = async (commentId) => {
        const confirmDelete = window.confirm(
            "Are you sure you want to delete this comment?"
        );
        if (!confirmDelete) return;

        try {
            await axios.delete(`${COMMENTS_API_URL}/${commentId}`);
            setComments(comments.filter((cmt) => cmt._id !== commentId));
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };

    const columns = [
        {
            name: "User Name",
            selector: (row) => row.userName || "Anonymous",
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.userEmail,
            sortable: true,
        },
        {
            name: "Comment",
            selector: (row) => row.comment,
            cell: (row) =>
                row.comment.length > 100
                    ? `${row.comment.slice(0, 100)}...`
                    : row.comment,
        },
        {
            name: "Published",
            selector: (row) => (row.isPublished ? "Yes" : "No"),
            sortable: true,
        },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toLocaleDateString(),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <>
                    <Link
                        to="#"
                        className="btn btn-primary btn-sm me-2"
                        title="Toggle Publish"
                        onClick={(e) => {
                            e.preventDefault();
                            handleTogglePublish(row);
                        }}
                    >
                        {row.isPublished ? (
                            <XCircle size={18} color="white" />
                        ) : (
                            <CheckCircle size={18} color="white" />


                        )}
                    </Link>
                    <Link
                        to="#"
                        className="btn btn-primary btn-sm me-2"
                        title="Delete"
                        onClick={(e) => {
                            e.preventDefault();
                            handleDelete(row._id);
                        }}
                    >
                        <Trash size={18} />
                    </Link>
                </>
            ),
        },
    ];

    return (
        <AdminLayout>
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                >
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                            Comments for Blog: {blogTitle || id}
                        </h1>
                    </div>
                    {/* Optional: Link to add a comment manually */}
                    <Link
                        to={`/comments/create?blog=${id}`}
                        className="btn btn-primary btn-sm me-2"
                    >
                        Add Comment
                    </Link>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                >
                    <div className="card card-body">
                        <DataTable
                            columns={columns}
                            data={comments}
                            progressPending={loading}
                            progressComponent={<div>Loading...</div>}
                            pagination
                            highlightOnHover
                            striped
                            sortIcon={<span className="caret" />}
                            noDataComponent={<div>No records found</div>}
                        />
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default CommentsIndex;
